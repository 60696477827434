<template>
  <section class="conOfAnnualMeeting h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Board', part2: 'Members' }" />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3" v-if="boardMembers && boardMembers.length > 0">
      <v-container>
        <v-row>
          <v-col
            md="3"
            sm="6"
            cols="12"
            v-for="(boardMember, index) in boardMembers"
            :key="index"
          >
            <div class="conofcard">
              <v-card class="mx-auto" max-width="370" tile elevation="0">
                <!-- :src="'https://img.youtube.com/vi/' + media.url + '/0.jpg'" -->
                <div class="conOfCardImg">
                  <v-img
                    height="250"
                    :src="boardMember.image"
                    alt="item of the Image"
                  ></v-img>
                </div>
                <v-card-title class="cardTitle">
                  <div class="titleSec">
                    {{ boardMember.name }}
                  </div>
                </v-card-title>
                <v-card-text class="conOfDesc">
                  <div class="cardDesc">
                    {{ boardMember.description }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <!-- <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row> -->
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "About Us",
        disabled: true,
      },
      {
        text: "Board Members",
        disabled: false,
      },
    ],
    boardMembers: null,
    isLoading: false,
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    getListData() {
      this.boardMembers = [];
      this.isLoading = true;

      apiServices.post("board-members").then((res) => {
        if (res) {
          this.boardMembers = res.data;
          this.pagination = res.meta;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_board-members.scss";
</style>
